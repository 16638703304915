export enum LocalStorageKeys {
  AuthUser = "de.ifaffm.portal.auth.authenticatedUser",
  CustomerNumbers = "de.ifaffm.portal.customerNumbers",
  NotificationFilterScope = "de.ifaffm.portal.notification.filter.scope",
  NotificationFilterSince = "de.ifaffm.portal.notification.filter.since",
  OrderConfirmationChoice = "de.ifaffm.portal.orderConfirmation.choice",
  OrderLastUpdate = "de.ifaffm.portal.orderState.update",
  TeachingConfirmed = "de.ifaffm.portal.teaching.confirmed",
  TeachingDiscoveredFeatures = "de.ifaffm.portal.teaching.discoveredFeatures",
}

// Maximale Anzahl der Veroeffentlichungstermin, die dem Benutzer zur Auswahl angeboten werden
//
// Mit vielen Terminen ist die Darstellung des aufgeklappten Dropdowns schlecht
// Außerdem enthält der Kalender Daten für 2099, die ein normaler Anwender nicht sehen sollte
export const LIMIT_VDATA_SELECTION = 8;

// Repräsentiert boolesche Werte in SAP, da boolesche Typen nicht unterstützt werden
export const SAP_BOOL_TRUTHY_VALUE = "X";
export const SAP_BOOL_FALSY_VALUE = " ";

// Test IDs für die Modals
export const LOCK_USER_MODAL_ID = "@lockUserModal";
export const UNLOCK_USER_MODAL_ID = "@unlockUserModal";
export const DELETE_USER_MODAL_ID = "@deleteUserModal";

export const CHANGE_PASSWORD_MODAL_ID = "@changePasswordModal";
export const EMAIL_NOTIFICATIONS_MODAL_ID = "@emailNotificationsModal";

// TEST IDs für Forms
export const DEFAULT_FORM_ID = "form";
export const ARTICLE_FORM_ID = "articleForm";
export const LOGIN_FORM_ID = "loginForm";
export const PHONE_NUMBER_FORM_ID = "phoneNumber";
